/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import App from 'next/app';
import NProgress from 'nprogress';
import Router from 'next/router';
// import * as Sentry from '@sentry/node';
import dynamic from 'next/dynamic';
import '@styles/global.css';
import '@components/molecules/style.css';
import '../src/styles/globals.css';
import "react-image-gallery/styles/css/image-gallery.css";
/* import '@components/styles/global.css';
import '@components/styles/text.global.css';
import '@components/molecules/style.css';
import '@brainhubeu/react-carousel/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'nprogress/nprogress.css'; */
const ToastContainer = dynamic(() => import('react-toastify').then(a => a.ToastContainer), { ssr: false });

import * as gtag from '../src/utils/service/gtag';

const handleRouteChange = (url) => {
  gtag.pageview(url)
}

Router.events.on('routeChangeStart', (url) => {
  console.log(`Loading: ${url}`)
  NProgress.start();
  handleRouteChange(url);
});
Router.events.off('routeChangeComplete', handleRouteChange);
Router.events.on('routeChangeComplete', () => {
  NProgress.done()
  if (document) {
    document?.getElementById('top')?.scrollIntoView();
  }
});
Router.events.on('routeChangeError', () => NProgress.done());

/* Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
}); */

// pages/_app.js
export function reportWebVitals(metric) {
  switch (metric.name) {
    case 'FCP':
      // handle FCP results
      // console.log({ metric });
      break
    case 'LCP':
      // handle LCP results
      break
    case 'CLS':
      // handle CLS results
      break
    case 'FID':
      // handle FID results
      // console.log({ metric });
      break
    case 'TTFB':
      // handle TTFB results
      break
    default:
      break
  }
}


export default class MyApp extends App {
  componentDidCatch(error, errorInfo) {
    /* Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    }); */

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return (
      <>
        <Component {...pageProps} />
        <ToastContainer />
        <style jsx>{`
          html {
              scroll-behavior: smooth;
          }
          
          /* targeting only firefox browser */
          @-moz-document url-prefix() {
            html {
              scroll-behavior: auto;
            }
          }
          @font-face {
            font-family: 'Montserrat';
            src: url('/static/font/Inter-Black.woff');
            src: url('/static/font/Inter-BlackItalic.woff');
            src: url('/static/font/Inter-Bold.woff');
            src: url('/static/font/Inter-Italic.woff');
            src: url('/static/font/Inter-Medium.woff');
            src: url('/static/font/Inter-Regular.woff');
            src: url('/static/font/Inter-SemiBold.woff');
            src: url('/static/font/Inter-Thin.woff');
          }
          * {
            font-family: 'Inter';
          }
        `}</style>
      </>
    );
  }
}
